import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { addCart } from '../redux/action'
import { delCart } from '../redux/action';

const Product = () => {

    const {id} = useParams();
    const [product, setProduct] = useState([]);
    const [loading, setLoading] = useState(false);

    const [cartbtn, setCartbtn] = useState("Add to Cart")

    const dispatch = useDispatch();
    const addProduct = (product) => {
        dispatch(addCart(product));
    }

    const delProduct = (product) => {
        dispatch(delCart(product));
    }

    const cartbtnState = (product) => {
        if (cartbtn === "Add to Cart") {
            addProduct(product);
            setCartbtn("Remove Item");
        } else {
            setCartbtn("Add to Cart");
            delProduct(product);
        }
    }
    useEffect(() => {
      
    const getProduct = async () => {
        setLoading(true);
        const responce = await fetch(`https://fakestoreapi.com/products/${id}`);
        setProduct(await responce.json());
        setLoading(false);
    }
        getProduct();
    }, [])

    const Loading = () => {

        return(
            <>
            <div className="col-md-6">
                <Skeleton height={400}/>
            </div>
            <div className="col-md-6" style={{lineHeight:2}}>
                <Skeleton height={50} width={300}/>
                <Skeleton height={75}/>
                <Skeleton height={25} width={150}/>
                <Skeleton height={50}/>
                <Skeleton height={150}/>
                <Skeleton height={50} width={100}/>
                <Skeleton height={50} width={100} style={{marginLeft:6}}/>
            </div>
            </>
        )
    }
    
    const ShowProduct = () => {

        return(
            <>
            <div className="col-md-6">
                <img src={product.image} alt={product.title}
                height='400px' width='400px' />
            </div>
            <div className="col-md-6">
                <h4 className="text-uppercase text-black-50">
                    {product.category}
                </h4>
                <h1 className='display-5'>{product.title}</h1>
                <p className="lead fw-bolder">
                    Rating {product.rating && product.rating.rate}
                    <i className="fa fa-star"></i>
                </p>
                <h3 className="display-6 fw-bold my-4">$ {product.price}</h3>
                <p className="lead">{product.description}</p>
                <button className="btn btn-outline-dark px-4 py-2" onClick={() => cartbtnState(product)}/*{onClick={() => addProduct(product)}}*/> {cartbtn} </button>
                <Link to='/cart' ><button className="btn btn-dark mx-2 py-2 px-3"> Go to cart</button></Link>
            </div>
            </>
        )
    }
  return (
    
    <div className="container py-5">
        <div className="row py-5">
            {loading ? <Loading /> : <ShowProduct />}
        </div>
    </div>
  )
}

export default Product