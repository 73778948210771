import React from 'react'
import Products from './Products'

const Home = () => {
    return (
        <div className='hero'>
            <div class="card bg-dark text-white border-0">
                <img src="/assets/back.jpg" class="card-img" alt="background" 
                height='550px'/>
                    <div class="card-img-overlay d-flex flex-column justify-content-center">
                        <div className="container">
                        <h5 class="card-title display-3 fw-bolder mb-0 ">Store Demo</h5>
                        <p class="card-text lead fs-2">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    </div>
                </div>
            </div>
            <Products />
        </div>
    )
}

export default Home