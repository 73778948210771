import React from 'react'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux'

const Navbar = () => {

  const state = useSelector((state) => state.handleCart)

  return (
    <>
      <nav class="navbar navbar-expand-lg bg-white py-3 shadow-sm">
        <div class="container">
          <Link to='/' class="navbar-brand fw-bold fs-4">Store Demo</Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
              <Link to='/' target='_blank' class="nav-link active">Home</Link>
              </li>
              <li class="nav-item">
              <Link to='/products' target='_blank' class="nav-link active">Products</Link>
              </li>
              <li class="nav-item">
              <Link to="/about" target='_blank' class="nav-link active">About</Link>
              </li>
              <li class="nav-item">
              <Link to='/contact' target='_blank' class="nav-link active">Contact</Link>
              </li>
            </ul>
            <div className="buttons">
              <Link to='/login'><a className="btn btn-outline-dark">
              <i className="fa fa-sign-in me-1"></i> Login</a></Link>
              <Link to='/register'><a className="btn btn-outline-dark ms-2">
              <i className="fa fa-user-plus me-1"></i> Register</a></Link>
              <Link to='/cart' className="btn btn-outline-dark ms-2">
              <i className="fa fa-shopping-cart me-1"></i> Cart ({state.length})</Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar